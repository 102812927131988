//Data:
function data() {
  return {
    containerShipImg: '/img/BUQUES/SVG/BaseShip.svg',
    bulkCarrierImg: '/img/BUQUES/SVG/shipbulkcarrierfull.svg',
    bulkCarrierImgReedited: '/img/BUQUES/SVG/SHIPBULKCARRIERFULLREEDITED.svg',
    craneImg: '/img/BUQUES/SVG/ContainerCrane.svg',
    craneBulkImg: '/img/BUQUES/SVG/grua1.svg',
    modalContainerShipImg: '/img/BUQUES/SVG/BaseShipeditadoazul.svg',
  };
}

export default {
  data,
};
