<template>
  <div class="container-card" v-if="!onlyNumbers">
    <div 
      v-for="(cols, index) in mergeBays"
      :key="keyGenerate(index)"
      class="container-col"
      :class="{ 
        'container-item-bottom': !alignTop ? true :  null,
        'container-col-pdf': pdf ? true :  null,
        'container-col-port': portSituation ? true :  null
      }"
    >
      <div class="container-code" v-if="showCode">
        {{cols.BayCode}}
      </div>
      <div
        v-for="(item, i) in cols.Tiers"
        :key="keyGenerate(i, true)"
        class="container-item tier-item"
        :style="{ backgroundColor: item.FgPortCompanyBranch ? '#ec631b' : '#fff' }"
        @click.prevent="handleClick(item)"
      >
        <!-- Tooltip -->
        <span
          class="sigeop-tooltip"
          v-if="tooltip && cols.BayCode"
        >
          {{ `Tier: ${item.Tier}` }}
        </span>
      </div>
      <div
        class="container-item no-borders"
        v-if="!cols.BayCode && cols.FgCrane"
        :style="{
          zIndex: '1',
        }"
      >
        <img
          :src="craneImg"
          class="grua-container"
          :class="{'small-crane': smallCranes ? true : null}"
          alt=""
        >
      </div>
    </div>
  </div>
  <div class="container-card" v-else>
    <div 
      v-for="(cols, index) in mergeBays"
      :key="'C'+index"
      class="container-col"
      :class="{ 'container-item-bottom': !alignTop ? true :  null }"
    >
      <div class="container-code" v-if="showCode && cols.BayCode">
        {{cols.BayCode}}
      </div>
      <div class="container-code" v-if="showCode && !cols.BayCode"></div>
    </div>
  </div>
</template>

<script>
import TransversalSrc from '@/_mixins/transversalViewSrc';

export default {
  name: "transversal-row",
  mixins: [TransversalSrc],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    showCode: {
      type: Boolean,
      default: false
    },
    alignTop: {
      type: Boolean,
      default: false
    },
    onlyNumbers: {
      type: Boolean,
      default: false
    },
    pdf: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: Boolean,
      default: true
    },
    portSituation: {
      type: Boolean,
      default: false      
    },
    smallCranes: {
      type: Boolean,
      default: false      
    },
  },
  data() {
    return {
      baseBays: [],
    };
  },
  created() {
  },
  methods: {
    generateBaseBays(limit) {
      this.baseBays = [];
      for (let index = 1; index < limit; index++) {
        if (Math.abs(index % 2) === 1) {
          this.baseBays.push({
            BayCode: index < 10 ? `00${index}` : `0${index}`,
            Tiers: [],
          });
        }
      }
    },
    keyGenerate(index, tiers = false) {
      if(tiers)
        return this.alignTop ? 'B'+index : 'A'+index;
      else
        return this.alignTop ? 'D'+index : 'E'+index;
    },
    handleClick(item) {
      this.$emit('click', item);
    }
  },
  computed: {
    mergeBays() {
      return this.items;
    },
  },
  watch: {
  }
};
</script>

<style scoped>
.container-card {
  width: 100%;
  min-width: 0;
  /* background: #000; */

  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
}
.container-col {
  width: 40px;
  margin: 5px 0 5px 5px;
  /* background: lightgray; */

  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: 12px;
  gap: 2px;

  transition: all 0.5s ease-in-out;
  position: relative;
}
.container-col-port {
  margin: 1px 0 0px 1px;
  grid-auto-rows: 7px;
  gap: 0px;
}
.container-col-pdf {
  margin: 2px 0 2px 2px;
  grid-auto-rows: 10px;
  gap: 0.8px;
}

.container-item {
  position: relative;
  background: #fff;
  border: 1px solid #000;
  border-radius: 2px;
  cursor: pointer;
}
/* .container-col:last-child {
  margin-right: 5px;
} */

.container-code {
  background: #fff;
  color: #000;
  font-size: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-item-bottom {
  align-self: end;
}

.container-item > img.grua-container {
  position: absolute;
  width: 600%;
  left: 23%;
  bottom: -35%;
  z-index: 1;
}
.grua-container-port {
  width: 835% !important;
  left: 15% !important;
}
.no-borders {
  border: none;
}

/* Tooltip */
.sigeop-tooltip {
  visibility: hidden;
  font-size: smaller;
  text-align: center;
  color: #fff;
  background-color: #000015;
  border-radius: .25rem;

  padding: .25rem .5rem;
  margin-bottom: 7px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  /* z-index: 500; */
  width: 70px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.sigeop-tooltip::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: calc(50% - 5px);
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}
.tier-item:hover .sigeop-tooltip {
  visibility: visible;
}
.container-item:hover {
  z-index: 30;
}

.tier-item {
  z-index: 2;
}

.grua-container.small-crane {
  width: 450% !important;
}
</style>