var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.onlyNumbers)?_c('div',{staticClass:"container-card"},_vm._l((_vm.mergeBays),function(cols,index){return _c('div',{key:_vm.keyGenerate(index),staticClass:"container-col",class:{ 
      'container-item-bottom': !_vm.alignTop ? true :  null,
      'container-col-pdf': _vm.pdf ? true :  null,
      'container-col-port': _vm.portSituation ? true :  null
    }},[(_vm.showCode)?_c('div',{staticClass:"container-code"},[_vm._v(" "+_vm._s(cols.BayCode)+" ")]):_vm._e(),_vm._l((cols.Tiers),function(item,i){return _c('div',{key:_vm.keyGenerate(i, true),staticClass:"container-item tier-item",style:({ backgroundColor: item.FgPortCompanyBranch ? '#ec631b' : '#fff' }),on:{"click":function($event){$event.preventDefault();return _vm.handleClick(item)}}},[(_vm.tooltip && cols.BayCode)?_c('span',{staticClass:"sigeop-tooltip"},[_vm._v(" "+_vm._s(`Tier: ${item.Tier}`)+" ")]):_vm._e()])}),(!cols.BayCode && cols.FgCrane)?_c('div',{staticClass:"container-item no-borders",style:({
        zIndex: '1',
      })},[_c('img',{staticClass:"grua-container",class:{'small-crane': _vm.smallCranes ? true : null},attrs:{"src":_vm.craneImg,"alt":""}})]):_vm._e()],2)}),0):_c('div',{staticClass:"container-card"},_vm._l((_vm.mergeBays),function(cols,index){return _c('div',{key:'C'+index,staticClass:"container-col",class:{ 'container-item-bottom': !_vm.alignTop ? true :  null }},[(_vm.showCode && cols.BayCode)?_c('div',{staticClass:"container-code"},[_vm._v(" "+_vm._s(cols.BayCode)+" ")]):_vm._e(),(_vm.showCode && !cols.BayCode)?_c('div',{staticClass:"container-code"}):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }