<template>
   <div class="col-sm-12">
     <apexchart width="100%" height='200px' type="bar" :options="chartOptions" :series="series"></apexchart>
   </div>
</template>
<script>
    import { NumberFormater } from '@/_helpers/funciones';
    function data() {
        return {
            SeriesData: [],
            Xaxis: [],
        };
    }
    function series(){
        for (let index = 0; index < this.ChartHold.length; index++) {
            this.SeriesData[index] = this.ChartHold[index].CantImplement;
        }
        return [{
            name: 'MOBOD',
            data: this.SeriesData,
        }]
    }
    function chartOptions(){
        for (let index = 0; index < this.ChartHold.length; index++) {
            this.Xaxis[index] = this.ChartHold[index].VesselHoldName;
        }
        return {
            chart: {
                height: '280px',
                animations: {
                    enabled: this.Animations,
                    animateGradually: {
                        enabled: this.Animations,
                    },
                    dynamicAnimation: {
                        enabled: this.Animations,
                    }
                }
            },
            colors: ['#1A3760'],
            xaxis: {
                categories: this.Xaxis,
            },
            yaxis: {
                title: {
                    text: this.$t('label.Gamela'),
                }
            },
            title: {
                text: this.$t('label.GamelaByWinery'),
                align: 'center',
            },
            dataLabels: {
              enabled: false,
            },
            tooltip: {
                enabled: true,
                y: {
                    formatter: function (val) {
                        return NumberFormater.formatNumber(val, 2);
                    }
                }
            },
        }    
    }
    export default {
        name:'sumary-chart-hold',
        data,
         props: {
            ChartHold: {
                type: Array,
                default: () => [],
            },
            Animations: {
                type: Boolean,
                default: true,
            },
        },
        computed:{
            series,
            chartOptions
        },
    }
</script>