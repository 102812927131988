<template>
  <div class="transversal">
    <div class="vista" v-if="isContainerShipMaster">
      <img :src="modalContainerShipImg" alt="" />
      <div class="top-half">
        <transversal-row
          :items="formatedToDeck"
          class="vessel-todeck"
          :tooltip="tooltip"
          :portSituation="portSituation"
          smallCranes
        />
      </div>
      <div class="bottom-half">
        <transversal-row
          :items="formatedUnderDeck"
          class="vessel-underdeck"
          alignTop
          :tooltip="tooltip"
          :portSituation="portSituation"
        />
      </div>
    </div>
    <!-- BulkCarrier -->
    <div
      class="vista"
      v-if="isBulkCarrierMaster || isGeneralCargoMaster"
      :class="{ 
        'overflow-values': !fullscreen ? true : null,
        'vista-operational': operationalSituation ? true : null,
      }"
    >
      <img :src="operationalSituation ? bulkCarrierImgReedited : bulkCarrierImg" alt="" />
      <div class="general-top-half" :class="{ 'top-half-operational': operationalSituation ? true : null }">
        <div
          class="hold-wrapper no-bg"
          :style="{ width: `calc( calc(100% / ${holdsQty}) )` }"
            v-for="(crane, i) in getCranes"
          :key="i"
        >
          <img
            :src="craneBulkImg"
            class="grua-general"
            :class="isGeneralCargoMaster && CantCrane <= 2 ? CraneGeneralCargoClasses(crane.Crane) : craneClasses(crane.Crane)" 
            :style="`${isGeneralCargoMaster && operationalSituation && CantCrane <= 2 ? (crane?.Crane?.FgBow ? 'height:80% !important; top:10px !important; left: -17% !important;' : 'height:80% !important; top:10px !important') : ''}`"
            v-if="crane.FgCrane"
            alt=""
          >
        </div>
      </div>
      <div class="general-bottom-half" :class="{ 'bottom-half-operational': operationalSituation ? true : null }">
        <div
          class="hold-wrapper"
          :style="{ width: `calc( calc(100% / ${holdsQty}) )` }"
            v-for="(hold, i) in getHolds"
          :key="i"
        >
          <div
            class="hold-name"
            v-if="hold.FgHold && isBulkCarrierMaster"
          >
              {{ hold.PositionJson[0].VesselHoldName }}
          </div>
            <img
            src="/img/BUQUES/SVG/tapa2.svg"
            class="hold-hatch"
            v-if="hold.FgHold && isBulkCarrierMaster"
            alt=""
          >
          <div v-if="isGeneralCargoMaster" class="hold-hatch-GeneralCargo">
            {{ hold.PositionJson[0].VesselHoldName }}
          </div>
          <div class="hold-info" v-if="hold.FgHold">
            <span v-if="isBulkCarrierMaster">
              {{ `${formatNumber(hold.PositionJson[0].Bale)} M³` }}
            </span>
            <span v-if="isGeneralCargoMaster">
              {{ `${formatNumber(hold.PositionJson[0].Volumen)} M³` }}
            </span>
          </div>
          <div class="bars-container" v-if="bulkCarrier || isBulkCarrierMaster || isGeneralCargoMaster">
            <div class="bars-item">
              <div v-if="isBulkCarrier"
                class="planificado"
                :style="{ height: `${getGraphValue(hold.PositionJson[0].PlannedTon, hold.PositionJson[0].OccupiedTon)}%` }"
              >
              </div>
              <div v-else-if="isGeneralCargo"
                class="planificado"
                :style="{ height: `${getGraphValue(hold.PositionJson[0].QuantityPlanning, hold.PositionJson[0].QuantityVisit)}%` }"
              >
              </div>
              <div v-if="isBulkCarrier"
                class="ocupado"
                :style="{ height: (hold.PositionJson[0].PlannedTon + hold.PositionJson[0].OccupiedTon) > 0 ? 
                  `calc(100% - ${getGraphValue(hold.PositionJson[0].PlannedTon, hold.PositionJson[0].OccupiedTon)}%)`
                  : '0%'
                }"
              >
              </div>
              <div v-else-if="isGeneralCargo"
                class="ocupado"
                :style="{ height: (hold.PositionJson[0].QuantityPlanning + hold.PositionJson[0].QuantityVisit) != 0 ?
                  `calc(100% - ${getGraphValue(hold.PositionJson[0].QuantityPlanning, hold.PositionJson[0].QuantityVisit)}%)` 
                  : '0%'
                }"
              >
              </div>
              <div class="container-values" :class="{ 'container-values-operational': operationalSituation ? true : null }">
                <!-- <span class="val-plan">{{formatNumber(hold.PositionJson[0].PlannedTon)}}</span>
                <span class="val-ocu">{{formatNumber(hold.PositionJson[0].OccupiedTon)}}</span> -->
                <div class="left-value text-right px-1">
                  <svg viewBox="0 0 50 16" v-if="isBulkCarrier" style="height: 100%;">
                    <text style="font-size:8px;" x="49" y="8" textLength="130%" class="val-plan" v-if="checkValueLength(hold.PositionJson[0].PlannedTon) > 9">
                      {{formatNumber(hold.PositionJson[0].PlannedTon)}}
                    </text>
                    <text style="font-size:8px;" x="49" y="8" class="val-plan" v-else>
                      {{formatNumber(hold.PositionJson[0].PlannedTon)}}
                    </text>
                  </svg>
                  <svg viewBox="0 0 50 16" v-else-if="isGeneralCargo" style="height: 100%;">
                    <text style="font-size:8px;" x="49" y="8" textLength="130%" class="val-plan" v-if="checkValueLength(hold.PositionJson[0].QuantityPlanning) > 9">
                      {{formatNumber(hold.PositionJson[0].QuantityPlanning)}}
                    </text>
                    <text style="font-size:8px;" x="49" y="8" class="val-plan" v-else>
                      {{formatNumber(hold.PositionJson[0].QuantityPlanning)}}
                    </text>
                  </svg>
                </div>
                <div class="right-value text-left px-1">
                  <svg viewBox="0 0 50 16" v-if="isBulkCarrier" style="height: 100%;">
                    <text style="font-size:8px;" x="1" y="8" class="val-ocu" textLength="130%" v-if="checkValueLength(hold.PositionJson[0].OccupiedTon) > 9">
                      {{formatNumber(hold.PositionJson[0].OccupiedTon)}}
                    </text>
                    <text style="font-size:8px;" x="1" y="8" class="val-ocu" v-else>
                      {{formatNumber(hold.PositionJson[0].OccupiedTon)}}
                    </text>
                  </svg>
                  <svg viewBox="0 0 50 16" v-else-if="isGeneralCargo" style="height: 100%;">
                    <text style="font-size:8px;" x="1" y="8" class="val-ocu" textLength="130%" v-if="checkValueLength(hold.PositionJson[0].QuantityVisit) > 9">
                      {{formatNumber(hold.PositionJson[0].QuantityVisit)}}
                    </text>
                    <text style="font-size:8px;" x="1" y="8" class="val-ocu" v-else>
                      {{formatNumber(hold.PositionJson[0].QuantityVisit)}}
                    </text>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="vessel-legend" :class="{ 'legend-operational': operationalSituation ? true : null }" v-if="legend">
        <div class="legend-item">
          <span class="circle planned-color mr-1"></span>
          {{ $t('label.planned') }}
        </div>
        <div class="legend-item">
          <span class="circle executed-color mr-1"></span>
          {{ $t('label.executedPortSituation') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { GenerateSvgPlaceholder, NumberFormater } from '@/_helpers/funciones';
import TransversalRow from "@/pages/buque/bays/transversal-row";
import TransversalSrc from '@/_mixins/transversalViewSrc';

//Data
function data() {
  return {
    isLoading: false,
    baysJson: [],
    generalJson: [],
    holdsQty: 0,
    errorMsgDone: false,
  }
}

//Created:
function created() {
  this.errorMsgDone = false;
  if(this.positionJson.length != 0 && (this.isBulkCarrierMaster || this.isGeneralCargoMaster)) {
    this.generalJson = this.positionJson;
    this.holdsQty = this.getHoldsCount(this.generalJson);
  }
}

//Metodos: 
function generateTiersArray(tierFrom, tierTo, bayCode) {
  if(!bayCode) return [];

  let arr = [],
    end = Number.parseInt(tierFrom, 10),
    start = Number.parseInt(tierTo, 10);

  for (let index = start; index >= end; index--) {
    if (Math.abs(index % 2) === 0) {
      arr.push({
        BayCode: bayCode,
        Tier: index < 10 ? `0${index}` : `${index}`,
        Code: `${bayCode}-${index}`,
      });
    }
  }
  return arr;
}
function getHoldsCount(arr) {
  if(arr.length == 0) return 0;

  let count = 0;
  for (let index = 0; index < arr.length; index++) {
    if(arr[index].FgHold)
      count++;
  }

  return count;
}

function formatNumber(number) {
  return NumberFormater.formatNumber(number, 2);
}

function craneClasses(crane){
  return {
    'crane-to-popa': crane.FgStern ? true : null,
    'crane-to-proa': crane.FgBow ? true : null,
    'grayscale-crane': !crane.FgActive ? true : null,
  }
}

function CraneGeneralCargoClasses(crane) {
  return {
    'crane-to-popa-GeneralCargo': crane.FgStern ? true : null,
    'crane-to-proa-GeneralCargo': crane.FgBow ? true : null,
    'grayscale-crane': !crane.FgActive ? true : null,
  }
}

function checkValueLength(val) {
  return `${this.formatNumber(val)}`.length;
}

//Computeds: 
function formatedToDeck() {
  return this.positionJson.map((bay) => {
    if (bay.BayCode && bay.FgBay) {
      return Object.assign(
        {},
        {
          BayCode: bay.BayCode,
          FgCrane: bay.FgCrane,
          Tiers:
            bay.PositionJson[0].ToDeckJson.length == 1
              ? this.generateTiersArray(
                  bay.PositionJson[0].ToDeckJson[0].TierToDeckFrom,
                  bay.PositionJson[0].ToDeckJson[0].TierToDeckTo,
                  bay.BayCode
                )
              : bay.PositionJson[0].ToDeckJson,
        }
      );
    } else if (!bay.BayCode && bay.FgCrane) {
      return Object.assign(
        {},
        {
          BayCode: bay.BayCode,
          FgCrane: bay.FgCrane,
          Tiers: [],
        }
      );
    }
  });
}
function formatedUnderDeck() {
  return this.positionJson.map((bay) => {
    if (bay.BayCode && bay.FgBay) {
      return Object.assign(
        {},
        {
          BayCode: bay.BayCode,
          Tiers:
            bay.PositionJson[0].UnderDeckJson.length == 1
              ? this.generateTiersArray(
                  bay.PositionJson[0].UnderDeckJson[0].TierUnderDeckFrom,
                  bay.PositionJson[0].UnderDeckJson[0].TierUnderDeckTo,
                  bay.BayCode
                )
              : bay.PositionJson[0].UnderDeckJson,
        }
      );
    } else if (!bay.BayCode && bay.FgCrane) {
      return Object.assign(
        {},
        {
          BayCode: bay.BayCode,
          Tiers: [],
        }
      );
    }
  });
}

function getHolds() {
  return this.generalJson.filter((item) => item.FgHold == true);
}
function getCranes() {
  let arr = [];
  for (let index = 0; index < this.generalJson.length; index++) {
    if(this.generalJson[index].FgHold){
      if (index-1 >= 0 &&!arr[index-1]?.FgHold&&!arr[index-1]?.Duplicate) {
        arr[index-1] = {
          ...arr[index-1],
          ...this.generalJson[index],
          FgCrane: true,
        };
        arr.push({
          Duplicate: true,
        });
      }else{
        arr.push(this.generalJson[index]);
      }
    }else{
      if(this.generalJson[index].FgCrane){
        if(!this.generalJson[index].PositionJson) {
          if(!this.errorMsgDone && !this.pdf){
            this.$notify({
              group: 'container',
              title: '¡Error!',
              text: `${this.$t('label.CraneErrorTransversalView')}`,
              type: "error"
            });
            this.errorMsgDone = true;
          }
        } else {
          if(index-1 >= 0 && !arr[index-1]?.Duplicate){
            arr[index-1].Crane = {...this.generalJson[index].PositionJson[0]};
            arr[index-1].FgCrane = true;
            arr.push({
              Duplicate: true,
            });
          }else{
            arr.push({
              ...this.generalJson[index],
              Crane: {...this.generalJson[index].PositionJson[0]},
              PositionJson: [],
            });
          }
        }
      }
    }
  }
  return arr.filter((item) => item.FgHold == true);
}
function getPdfClass() {
  return {
    'pdf-transversal': this.pdf ? true : null,
    'manifest-vista': this.manifest ? true : null,
    'pdf-manifest-transversal': this.manifestPdf ? true : null,
  }
}

function getPdfFont() {
  return {
    'pdf-transversal-fuente': this.pdf ? true : null,
  }
}
function getGraphValue(primary, secondary){
  let total = primary + secondary;
  let percent = 0;
  if (total > 0) {
    percent = (primary * 100) / total;
  }
  return percent;
}

function CantCrane() {
  return this.getCranes.filter((item) => item.FgCrane).length;
}

function isBulkCarrierMaster(){
  return this.vesselType === process.env.VUE_APP_BULK_VESSEL_ID;
}

function isContainerShipMaster() {
  return this.vesselType === process.env.VUE_APP_CONTAINER_SHIP_VESSEL_ID;
}

function isGeneralCargoMaster() {
  return this.vesselType === process.env.VUE_APP_GENERAL_CARGO_VESSEL_ID;
}

function isBulkCarrier() {
  return this.TpVesselId === process.env.VUE_APP_BULK_VESSEL_ID;
}

function isGeneralCargo() {
  return this.TpVesselId === process.env.VUE_APP_GENERAL_CARGO_VESSEL_ID;
}

export default {
  name: 'transverla-view-small',
  mixins: [TransversalSrc],
  components: {
    TransversalRow
  },
  props: {
    vesselType:{
      type: String,
      default: ''
    },
    TpVesselId: {
      type: String,
      default: ''
    },
    tooltip: {
      type: Boolean,
      default: false
    },
    portSituation: {
      type: Boolean,
      default: true
    },
    positionJson: {
      type: Array,
      default: () => [],
    },
    bulkCarrier: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    legend: {
      type: Boolean,
      default: false
    },
    operationalSituation: {
      type: Boolean,
      default: false,
    },
  },
  data,
  created,
  methods: {
    generateTiersArray,
    getHoldsCount,
    formatNumber,
    craneClasses,
    getGraphValue,
    CraneGeneralCargoClasses,
    checkValueLength,
  },
  computed: {
    formatedToDeck,
    formatedUnderDeck,
    getHolds,
    getCranes,
    getPdfClass,
    getPdfFont,
    CantCrane,
    isBulkCarrierMaster,
    isContainerShipMaster,
    isGeneralCargoMaster,
    isBulkCarrier,
    isGeneralCargo,
  },
  watch: {
    positionJson: {
      handler: function (val, oldVal) {
        this.errorMsgDone = false;
        if(this.isBulkCarrierMaster || this.isGeneralCargoMaster) {
          this.generalJson = this.positionJson;
          this.holdsQty = this.getHoldsCount(this.generalJson);
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.transversal {
  width: 100%;
  /* height: 40%; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.transversal .titulo {
  font-size: 1.3rem;
  font-weight: bold;
  align-self: flex-end;
  margin: 5px 20px 20px 0;
}
.transversal .vista {
  width: 750px;
  height: 200px;
  position: relative;
  /* overflow: hidden; */
}
.transversal .vista img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.transversal .vista .top-half {
  /*IMPORTANT*/
  position: absolute;
  top: 0;
  bottom: 32%;
  /* left: 22%;
  right: 12%; */
  left: 25%;
  right: 15%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.transversal .vista .bottom-half {
  /*IMPORTANT*/
  position: absolute;
  top: 68%;
  bottom: 2%;
  /* left: 22%;
  right: 12%; */
  left: 25%;
  right: 15%;
  display: flex;
  flex-direction: column;
}

/* Bulk Carrier */
.transversal .vista .general-top-half { /*IMPORTANT*/
  position:absolute;
  top: 30%;
  bottom: 30%;
  left: 24%;
  right: 13%;
  display: flex;
  flex-direction: row-reverse;
}
.transversal .vista .general-bottom-half { /*IMPORTANT*/
  position:absolute;
  top: 67.5%;
  bottom: 1%;
  left: 24%;
  right: 13%;
  display: flex;
  flex-direction: row-reverse;
}

.transversal .vista .vessel-legend { /*IMPORTANT*/
  position: absolute;
  top: 5%;
  left: 50%;
  width: 300px;
  height: 30px;
  display: flex;
  transform: translateX(-50%);
  align-items: center;
  justify-content: space-evenly;
}
.transversal .vista .legend-item {
  display: flex;
  align-items: center;
}
.circle{
  height: 15px;
  width: 15px;
  background: #666666;
  display: inline-block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.planned-color{
  background: #1a3760;
}
.executed-color{
  background: #e96120;
}

/* Carga General */
.hold-wrapper {
  position: relative;
  width: calc(calc(100% / 2));
  border: 1px solid #000;
  background-color: #fff;
  font-size: 0.6rem;
}
.hold-wrapper .hold-name {
  /* border: 1px solid #000; */
  /* padding: 2px; */
  /* background-color: #fff; */
  text-align: center;
  position: absolute;
  top: -30%;
  width: 70%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 10px;
  color: #000;
}
.hold-wrapper .hold-info {
  text-align: center;
  padding: 2px;
  border-bottom: 1px solid #000;
}

.hold-wrapper img.grua-general {
  /* border: 1px solid #000; */
  position: absolute;
  width: 90%;
  /* bottom: -1%; */
  /* left: -14.5%; */
  left: -11.5%;
}
.no-bg {
  background-color: transparent;
  border: none;
}

/* Sizes */
.pdf-transversal {
  width: 985px !important;
  height: 327px !important;
}
.pdf-transversal-fuente {
  font-size: 0.75rem !important;
}

/* Manifest */
.manifest-vista {
  width:1075px !important;
}
.manifest-general-top-half {
  bottom: 35% !important;
  top: 37% !important;
}
.manifest-general-bottom-half {
  top: 64.2% !important;
  bottom: 11.4% !important;
}
.manifest-hold-name {
  top: -15% !important;
}
.manifest-card{ 
  border: none !important;
}
.hold-manifest {
  width: 100%;
  height: 74%;
  display: flex;
  flex-direction: column-reverse;
}
.bl-item {
  cursor: pointer;
}
.crane-to-popa {
  transform: scaleX(-1);
  left: -79% !important;
}
.crane-to-proa {
  left: -11.5% !important;
}
.manifestPdf-crane-to-popa {
  transform: scaleX(-1);
  left: -78.1% !important;
}
.manifestPdf-crane-to-proa {
  left: -12% !important;
}
.manifestPdf-general-top-half{
  bottom: 32% !important;
}
.manifestPdf-general-bottom-half{
  bottom: 2.3% !important;
  top: 67% !important;
}
.hold-manifest-pdf{
  height: 73% !important;
}
.pdf-manifest-card {
  border: none !important;
  margin-bottom: 0 !important;
}
.pdf-manifest-card-body {
  padding: 0 !important;
}
.pdf-manifest-transversal {
  width: 870px !important;
  height: 265px !important;
}
.not-overflow {
  overflow: hidden;
}
.manifest-occupied{ 
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 85%;
  width: 70%;
  text-align: center;
  background-color: #002060;
  color: #fff;
  font-weight: bold;
  font-size: 10px;
  padding: 2px 0;
}
.hold-hatch {
  position: absolute;
  top: -10% !important;
  width: 90% !important;
  height: 25% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* Graph Bar */
.bars-container {
  width: 100%;
  /* height: 100%; */
  /* background-color: lightcoral; */
  position: absolute;
  top: 19.067px;
  bottom: 0;
}
.bars-item {
  width: 100%;
  height: 100%;

  padding: 1px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
}
.bars-item .planificado {
  width: 30%;
  height: 0%;
  background-color: #1a3760;
  /* border: 1px solid #000; */
  margin-right: 2px;
  transition: height 1s ease-in-out;
}
.bars-item .ocupado {
  width: 30%;
  height: 0%;
  background-color: #e96120;
  /* border: 1px solid #000; */
  transition: height 1s ease-in-out;
}
.bars-item .container-values {
  position: absolute;
  bottom: -45%;
  /* border: 1px solid #000; */
  width: 100%;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  height: 15px;
}
.bars-item .container-values > svg {
  width: 100%;
}
.bars-item .container-values > svg text {
  font-family: "Verdana", Helvetica, Arial;
  font-size: 0.5rem;
  font-weight: bold;
}
.bars-item .container-values .left-value,
.bars-item .container-values .right-value {
  width: 50%;
}
/* .bars-item .container-values .val-plan,
.bars-item .container-values .val-ocu{
  font-size: 0.5rem;
  font-weight: bold;
  width: 50%;
} */
.bars-item .container-values .val-plan {
  /* margin-right: 2px;
  color: #1a3760;
  text-align: right; */
  fill: #1a3760;
  text-anchor: end;
}
.bars-item .container-values .val-ocu {
  /* text-align: left;
  color: #e96120; */
  fill: #e96120;
}

.overflow-values {
  overflow-y: scroll;
}

/* Situacion Operativa */
.vista-operational {
  width: 856px !important;
}
.top-half-operational {
  top: -3% !important;
  bottom: 30% !important;
  left: 15% !important;
  right: 10% !important;
}
.bottom-half-operational {
  top: 57% !important;
  bottom: 0% !important;
  left: 15% !important;
  right: 10% !important;
}
.legend-operational {
  top: -13% !important;
}
.container-values-operational { 
  bottom: -30% !important;
}
.grayscale-crane {
  filter: invert(50%) grayscale(1) brightness(.8);
}
.crane-to-proa-GeneralCargo {
  transform: scaleX(-1);
  left: -25% !important;
}
.crane-to-popa-GeneralCargo {
  left: 25% !important;
}
.hold-hatch-GeneralCargo {
  border: 1px solid;
  background: #ffffff;
  position: absolute;
  text-align: center;
  top: -14% !important;
  width: 90% !important;
  height: 27% !important;
  left: 50% !important;
  transform: translate(-50%,-50%);
  font-weight: 700;
  font-size: 10px;
  color: #000;
}
</style>